
    @import "config.scss";
 
.wrapper {
  @include for-desktop-up {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  // margin-top: 25px;
  // margin-left: 25px;
  position: relative;
  height: 80vw;
  margin: 20px;

  @include for-desktop-up {
    height: 33vw;
  }

  a {
    color: var(--color-primary);
  }

  h1 {
    padding-bottom: 35px;
  }

  p {
    padding-bottom: 20px;
  }
}

.storebranchList {
  @include for-desktop-up {
    // display: none;
    flex: 1;
    overflow: scroll;
    height: 79vh;
    padding: 10px;
  }
}

.divider {
  display: none;

  @include for-desktop-up {
    height: inherit;
    display: block;
    margin-inline: 20px;
  }
}

.sidebar {
  display: none;
  width: 250px;
  // height: fit-content;

  @include for-desktop-up {
    display: flex;
    margin-left: 35px;
  }

  .faqItem {
    display: block;
    color: white;
    padding: 15px 12px;

    &--active {
      font-weight: 700;
      color: var(--color-primary);
    }
  }

  p,
  strong,
  li {
    margin-bottom: 10px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    line-height: 1.3rem;
  }

  strong {
    font-weight: 700;
  }

  ul {
    list-style: unset;
    width: 100%;
  }
}
