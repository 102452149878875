
    @import "config.scss";
 
.containerSelect {
  @include errorMessage;

  select {
    width: 100%;
    height: 45px;
    padding: 0 28px;
    appearance: none;
    border-radius: 8px;
    font-weight: 800;

    &::placeholder {
      font-weight: 800;
    }
  }

  &.brHard {
    select {
      border-radius: 25px;
    }
  }

  &.outlined {
    select {
      border: 1px solid;
    }
  }

  &.filled {
    select {
      border: none;
    }
  }
}

.container {
  position: relative;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-track-piece {
    margin-block: 0.5rem;
    border-radius: 50px;
    background-color: #cccccc;
  }

  & > button {
    max-height: 40px;
  }

  .content {
    display: none;
    width: 100%;
    position: absolute;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px 0;
    border-radius: 10px;
    margin-top: 5px;
    z-index: var(--z-content);

    &--visible {
      display: block;
    }
  }
  .relativeContent {
    position: relative;
  }
}

.title {
  margin-bottom: 10px;
}

.errorMessage {
  @include errorMessage;
}
.list {
  max-height: 17rem;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.shadow {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 20px;
  bottom: 0;
  background: -webkit-linear-gradient(
    90deg,
    rgba(256, 256, 256, 0.35),
    transparent
  );
  background: linear-gradient(0deg, rgba(256, 256, 256, 1), transparent);
}
